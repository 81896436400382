<template>
  <modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal__content">
      <div class="modal__header">
        <h2 class="modal__title">
          Avaliar coleta
        </h2>
      </div>
      <div class="d-flex flex-wrap">
        <div>
          <label>O coletor foi educado?</label>
          <br>
          <star-rating v-model="question1" />
        </div>
        <div style="margin-top: 40px;">
          <label>O coletor estava apresentável?</label>
          <br>
          <star-rating v-model="question2" />
        </div>
        <div style="margin-top: 40px;">
          <label>Chegou no dia e horário e combinado?</label>
          <br>
          <star-rating v-model="question3" />
        </div>
      </div>
    </div>
    <div style="text-align: center;">
      <button
        type="button"
        @click="saveRating"
        class="button_outline"
      >
        Enviar avaliação
      </button>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue'
import swal from 'sweetalert'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import StarRating from 'vue-star-rating'

const defaultForm = {
  id: null,
  form: null,
  collector_id: null,
  item_id: null,
  recyclable_items: null,
  not_recyclable_items: null,
  address_id: null,
  price: null
}
const defaultFormAddress = {
  zip_code: null,
  district: '',
  complement: '',
  street: '',
  number: '',
  city_id: '',
  province_id: '',
  address_id: ''
}
const defaultLoadedByZip = {
  street: false,
  number: false,
  district: false,
  complement: false
}
export default Vue.extend({
  data: () => ({
    forms: [
      { form: 1, label: 'Coletar no meu endereço' },
      { form: 2, label: 'Levar no ponto de coleta' }
    ],
    formData: { ...defaultForm },
    formAddress: { ...defaultFormAddress },
    loadedByZip: { ...defaultLoadedByZip },
    rules: {},
    addresses: [],
    collectors: [],
    collectPoints: [],
    loading: false,
    local: 0,
    deletingAddress: false,
    recyclable: {
      yes: true,
      not: false
    },
    price: null,
    question1: 0,
    question2: 0,
    question3: 0
  }),
  computed: {
    ...mapGetters('province', ['provinces']),
    ...mapGetters('city', ['cities']),
    valid () {
      return true
    },
    validAddress () {
      const form = this.formAddress
      if (!form.zip_code) return false
      if (!form.district) return false
      if (!form.street) return false
      if (!form.number) return false
      if (!form.province_id) return false
      if (!form.city_id) return false

      return true
    }
  },
  methods: {
    ...mapActions('closet', ['fetchItems']),
    ...mapActions('province', { fetchProvinces: 'fetch' }),
    ...mapActions('city', { fetchCities: 'fetch', getViaCEP: 'getViaCEP' }),
    async saveRating () {
      const url = 'collection/saverating'

      try {
        console.log('teste', this.formData)
        const body = {
          coletor_id: this.formData.coletor_id,
          order_id: this.formData.order_id,
          question_1: this.question1,
          question_2: this.question2,
          question_3: this.question3
        }

        const { data, status } = await axios.post(url, body)
        this.alert(status === 200 ? 'success' : 'error', data.message)
        this.closeForm()
      } catch (error) {
        const { status, data } = error.response
        this.alert(status === 200 ? 'success' : 'error', data.message)
      } finally {
        this.loadingCollection = false
      }
    },
    async saveCollectionPoint () {
      if (!this.valid) {
        swal({
          title: 'Atenção!',
          text: 'Preencha todos os campos.',
          icon: 'error',
          button: 'Ok'
        })
        return
      }
      const url = 'collection/saveCollectPoint'
      try {
        const body = {
          id: this.formData.id,
          collector_id: this.formData.collector_id
        }
        const { data, status } = await axios.post(url, body)
        this.alert(status === 200 ? 'success' : 'error', data.message)
        this.fetchItems(0)
        this.closeForm()
      } catch (error) {
        const { status, data } = error.response
        this.alert(status === 200 ? 'success' : 'error', data.message)
      } finally {
        this.loadingCollection = false
      }
    },
    async saveCollectionPointsssss () {
      if (!this.valid) {
        swal({
          title: 'Atenção!',
          text: 'Preencha todos os campos.',
          icon: 'error',
          button: 'Ok'
        })
        return
      }
      const url = 'collection/saveCollectPoint'
      try {
        const { data, status } = await axios.post(url, this.formData)
        this.alert(status === 200 ? 'success' : 'error', data.message)
        this.fetchItems(0)
        this.closeForm()
      } catch (error) {
        const { status, data } = error.response
        this.alert(status === 200 ? 'success' : 'error', data.message)
      } finally {
        this.loadingCollection = false
      }
    },
    async saveAddress () {
      if (!this.validAddress) {
        return this.alert(
          'error',
          'Preencha corretamente todos campos de endereço.',
          null
        )
      }

      try {
        await axios.post('addresses', { ...this.formAddress, type: 'PONTO' })
        this.getAddresses()
        this.alert('success', 'Endereço adicionado com sucesso', null)
        this.formAddress = { ...defaultFormAddress }
      } catch (error) {
        this.alert('error', 'Ocorreu um erro ao adicionar endereço.', null)
      }
    },
    destroyAddress ({ id }) {
      if (this.deletingAddress) return
      this.deletingAddress = true
      const deleteAddress = async () => {
        try {
          const url = `addresses/${id}`
          await axios.delete(url)
          this.alert('success', 'Endereço deletado com sucesso', null)
          this.getAddresses()
          return true
        } catch (error) {
          return error
        }
      }

      const showError = e => {
        this.alert('error', 'Erro ao deletar endereço', null)
      }

      swal({
        title: 'Tem certeza?',
        text: 'Após deletado não sera possível recuperar o registro',
        icon: 'warning',
        buttons: ['Cancelar', true]
      })
        .then(async res => {
          if (!res) return
          const deleted = await deleteAddress()
          if (deleted === true) return
          throw deleted
        })
        .catch(showError)
        .finally(() => {
          this.deletingAddress = false
        })
    },
    async getAddresses () {
      const { data } = await axios.get('addresses')
      this.addresses = data
    },
    async getCollectPoints (selected, tipo) {
      const url = selected + `/${this.formData.item_id}`
      const { data } = await axios.get(url)
      this.collectors = data.data
    },
    async setAddress (selected, price) {
      this.address_id = selected
      this.price = price
    },
    async getCollectors (itemId) {
      const url = `collectorsByItem/${itemId}`
      const { data } = await axios.get(url)
      this.collectors = data
    },
    closeForm () {
      this.$emit('change-modal', false)
    },
    clearSelected () {
      const selects = Array.from(document.querySelectorAll('.selected-form'))
      selects.map(i => (i.checked = false))
    },
    alert (
      type = 'success',
      text = 'Verifique todos os campos.',
      successAction = () => this.$router.go('/armario')
    ) {
      swal({
        title: type === 'success' ? 'Sucesso!' : 'Ooops!',
        icon: type === 'success' ? 'success' : 'error',
        text,
        button: type === 'success' ? null : 'Ok',
        timer: type === 'success' ? 1250 : 3000
      }).then(() => {
        if (type === 'success' && successAction !== null) successAction()
      })
    }
  },

  props: {
    modal: Boolean,
    selectedMaterial: Object
  },
  components: {
    modal: () => import('@/components/modal'),
    StarRating
  },
  created () {
    this.getAddresses()
  },
  mounted () {
    this.fetchProvinces()
  },
  watch: {
    async 'formAddress.zip_code' (val) {
      if (!val || val.length < 8) return
      const data = await this.getViaCEP({ zipCode: val })
      this.formAddress = Object.assign(this.formAddress, data)
      Object.keys(data).map(item => {
        this.loadedByZip[item] = !!data[item]
      })
    },
    modal () {
      this.formData = { ...defaultForm }
      this.clearSelected()
    },
    selectedMaterial: {
      deep: true,
      handler (val) {
        if (!val) return null
        this.formData = val
      }
    }
  }
})
</script>

<style scoped>
.have-destination {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.red {
  color: #ce2c2c;
}
button {
  margin: auto;
  margin-top: 1rem;
}
.banks__item--content {
  margin-left: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banks__item--content div:last-child {
  padding-left: 16px;
}

.banks__item input:checked + label::after {
  left: 10px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.recyclable {
  margin: 1.5rem 0;
  position: relative;
}

.recyclable-section p {
  position: relative;
  cursor: pointer;
}

.modal-chevron {
  color: #05b446;
  position: absolute;
  right: 10px;
  font-size: 22px;
  top: 50%;
  transition: 0.2s;
  transform: translateY(-50%) rotate(360deg);
}
.modal-chevron.open {
  transform: translateY(-50%) rotate(180deg);
}

.recyclable-content {
  margin: 1rem auto 2rem auto;
  width: calc(100% - 1rem);
}

.recyclable-content li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.recyclable-content li i {
  margin-right: 1rem;
  font-size: 14px;
  display: block;
}

.recyclable-content li i.fa-check {
  color: #47be71;
}
</style>
